// Here you can add other styles
// FIRST UPDATE AT 26/02/2020
//Modified class = 1. Button

@each $color, $value in $theme-colors {
    .bg-login-#{$color} {
        background-image: linear-gradient(to right, $value, $value), url("../assets/bg/bg-login.png");
        background-position: center;
        background-size: cover;
    }
}

@mixin customBreakpoint($class) {
    @if $class == xxl {
        @media (min-width: 1920px) {
            @content;
        }
    } @else if $class == ul {
        @media (min-width: 2560px) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xxl , ul";
    }
}

.kda-page-title-font {
    @extend .h1;
    font-weight: bold;

    @include media-breakpoint-only(lg) {
        font-size: 3rem !important;
    }

    @include media-breakpoint-only(xl) {
        font-size: 3.75rem !important;
    }

    @include customBreakpoint(xxl) {
        font-size: 5rem !important;
    }
}

.rounded-border-25 {
    border-radius: 25px;
}

.custom-hover-pointer {
    cursor: pointer;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 0.6rem 2rem 0.6rem;
    font-size: 1.3rem;
    line-height: 0.6;
    border-radius: 0.5rem;
    border-width: 0em;
    outline: none !important;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.4rem 1rem 0.6rem;
    font-size: 0.9rem;
    line-height: 0.6;
    border-radius: 0.5rem;
    border-width: 0em;
    outline: none !important;
}

.kda-server-btn-red {
    @extend .custom-hover-pointer;
    @extend .btn-primary;
    @extend .btn-lg;
    border-radius: 1rem;
    line-height: 1.2;
    display: block;
    &:disabled {
        color: darken(white, 30%);
        background-color: darken(#ec2028, 30%);
    }
}

.kda-server-btn-black {
    @extend .btn-outline-dark;
    @extend .btn-lg;
    background-color: white;
    color: black;
    border-radius: 1rem;
    line-height: 1.2;
    border: 0.5px solid;
    display: block;
}

$elements: kda-server-chart-container, kda-server-chart-text-container, kda-server-title-text-container;

.kda-server-anchor {
    position: relative !important;
}

$indexes: 0, 1, 2, 3, 4, 5;
@each $index in $indexes {
    .kda-server-anchor .kda-server-child-#{$index} {
        z-index: $index !important;
        @if $index > 0 {
            position: absolute !important;
            top: 0;
            left: 0;
        }
    }
}

.kda-server-text-center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.kda-server-text-top-left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.kda-server-text-center-container .kda-server-text-center-fonts {
    margin: 0 0 2px;
    padding: 0;
    font-size: 0.8rem;
    line-height: 0.8;
}

@include media-breakpoint-only(sm) {
    .kda-server-text-center-fonts {
        font-size: 1.5rem !important;
    }
}

@include media-breakpoint-up(md) {
    .kda-server-text-center-fonts {
        font-size: 2rem !important;
    }
}

@include media-breakpoint-only(lg) {
    .kda-server-text-center-fonts {
        font-size: 2rem !important;
    }
}

@include media-breakpoint-only(xl) {
    .kda-server-text-center-fonts {
        font-size: 3.5rem !important;
    }
}

.kda-server-text-sub-title-font,
.kda-server-text-server-time-font,
.kda-server-text-used-space-font,
.kda-server-text-unit-font,
.kda-server-text-total-space-font {
    margin-top: 0em;
    margin-bottom: 0em;
    line-height: 1.2;
}

.kda-server-text-used-space-font,
.kda-server-text-unit-font {
    margin-bottom: 10px;
    margin-top: 10px;
}

.kda-server-text-sub-title-font {
    font-size: smaller;
}

.kda-server-text-server-time-font {
    font-size: x-small;
}

.kda-server-text-used-space-font {
    font-size: medium;
}

.kda-server-text-unit-font {
    font-size: small;
}

.kda-server-text-total-space-font {
    font-size: smaller;
}

@include media-breakpoint-up(sm) {
    .kda-server-text-sub-title-font {
        font-size: large;
    }

    .kda-server-text-server-time-font {
        font-size: medium;
    }

    .kda-server-text-used-space-font {
        font-size: xx-large;
    }

    .kda-server-text-unit-font {
        font-size: x-large;
    }

    .kda-server-text-total-space-font {
        font-size: large;
    }
}

@include media-breakpoint-up(xxl) {
    .kda-server-text-sub-title-font {
        font-size: 2rem !important;
    }

    .kda-server-text-server-time-font {
        font-size: 1.5rem !important;
    }

    .kda-server-text-used-space-font {
        font-size: 3rem !important;
    }

    .kda-server-text-unit-font {
        font-size: 2.5rem !important;
    }

    .kda-server-text-total-space-font {
        font-size: 2.5rem !important;
    }
}

@include customBreakpoint(xxl) {
    .kda-server-text-sub-title-font {
        font-size: 2.5rem !important;
    }

    .kda-server-text-server-time-font {
        font-size: 2rem !important;
    }

    .kda-server-text-used-space-font {
        font-size: 3.5rem !important;
    }

    .kda-server-text-unit-font {
        font-size: 3rem !important;
    }

    .kda-server-text-total-space-font {
        font-size: 3rem !important;
    }
}

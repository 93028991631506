/**
 * Variables override : 
 * 1.Theme Colors
 * 2.Space used in many, like margin or padding
 */
$theme-colors: (
  "primary": #ec2028,
  "secondary": #d8d8d8,
  "secondary-darken": #949494,
  "canvas": #efefef,
  "white": #ffffff,
  "white-transparent-2": rgba(255, 255, 255, 0.2),
  "black": #000000,
  "black-transparent-2": rgba(0, 0, 0, 0.2),
  "black-transparent-8": rgba(0, 0, 0, 0.8),
  "used": #808080,
  "safe": #32cd32,
  "warning": #ffff00,
  "danger": #800000,
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ( $spacer * 0.25 ),  // 4px
    2: ( $spacer * 0.5  ),  // 8px
    3: ( $spacer * 1    ),  // 16px
    4: ( $spacer * 1.5  ),  // 24px
    5: ( $spacer * 3    ),  // 48px
    6: ( $spacer * 4    ),  // 64px
    7: ( $spacer * 5    ),  // 80px
    8: ( $spacer * 6.25 ),  // 100px
    9: ( $spacer * 7.5  ),  // 120px
    10:( $spacer * 9.375),  // 150px
  ),
  $spacers
);
